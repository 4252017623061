const SEARCH_BOX = 'searchbox'

export const initSelectControl = (
  idElement: string = SEARCH_BOX,
  event: KeyboardEvent
) => {
    const key = event.key;
    //console.log(key)
    if (key !== "ArrowDown" && key !== "ArrowUp" && key !== "Enter") {
        return
    }
    // return the others key if not up down enter
    const dropdownItems = document.querySelectorAll('.dropdown-item') as NodeListOf<HTMLElement>;
    let {currentIndex, newIndex} = getCurrentIndex(dropdownItems);

    if (key === "ArrowDown" || key === "ArrowUp") {
        event.preventDefault();
        controlDropdown(dropdownItems, currentIndex, newIndex, key, idElement);
    } else if (key === "Enter") {
        // Do nothing if not focus on input searchbox
        if (document.activeElement?.id !== idElement) {
            return;
        }

        if ((currentIndex % dropdownItems.length) >= 0) {
            newIndex = currentIndex % dropdownItems.length;
        }
        // Press Enter key then call click the filter
        document.getElementById(`dropdown-${newIndex}`)?.click()
        //toggleDropdown(false);
    }
}

const getCurrentIndex = (
  dropdownItems: NodeListOf<HTMLElement> | { classList: { contains: (arg0: string) => any; }; }[]
) => {
    let currentIndex = 0;
    let newIndex = 0;
    // Find the currently selected element
    dropdownItems.forEach((element: { classList: { contains: (arg0: string) => any; }; }, index: number) => {
        if (element.classList.contains('dropdown-selected')) {
            currentIndex = index;
        }
    });
    return {
        currentIndex,
        newIndex
    }
}

const controlDropdown = (
  dropdownItems: any[] | NodeListOf<HTMLElement>,
  currentIndex: number,
  newIndex: number,
  key: string,
  idElement: string = SEARCH_BOX
) => {
    dropdownItems.forEach((element: { classList: { remove: (arg0: string) => void; }; }) => {
        element.classList.remove('dropdown-selected');
    });

    if (key === "ArrowDown") {
        newIndex = (currentIndex + 1) % dropdownItems.length
    } else if (key === "ArrowUp") {
        newIndex = (currentIndex - 1 + dropdownItems.length) % dropdownItems.length
    }

    if (dropdownItems[newIndex]) {
        // Add the 'selected' class to the newly selected element
        dropdownItems[newIndex].classList.add('dropdown-selected');
        const meiliHits= document.getElementById(`meili-hits-${idElement}`) as HTMLElement;
        console.log(meiliHits, dropdownItems[newIndex].offsetTop)
        // Scroll to the newly selected element
        meiliHits.scrollTo({
            top: dropdownItems[newIndex].offsetTop,
            behavior: 'smooth'
        });
    }
}

//eventListener(document.getElementById(SEARCH_BOX), 'keydown', initSelectControl);

